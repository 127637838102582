
import './App.css';
import Web3 from 'web3'
import ERC721 from '../abis/ERC721.json'
import React, { Component } from 'react';
import BatchTransfer from '../abis/BatchTransfer.json'

class App extends Component {
  async componentWillMount() {
    await this.loadWeb3()
    await this.loadBlockchainData()
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
  }

  async loadBlockchainData() {
    const web3 = window.web3
    // Load account
    const accounts = await web3.eth.getAccounts()
    this.setState({ account: accounts[0] })
    console.log(accounts)
    // Load smart contract: BatchTransfer
    try {
      const abi = BatchTransfer.abi
      const contractAddr = "0xfc8c3f7363505CEb08b9E6Ac601fEb1A246467cB"
      this.setState({ contractAddr })
      const contract = new window.web3.eth.Contract(abi, contractAddr)
      this.setState({ contract })
    } catch (err) {
      window.alert(err)
    }
  }

  constructor(props) {
    super(props)
    this.state = { 
       tokens: [{ address: [{str: "", err: ""}], id : [{str: "", err: ""}] }],
       account: '',
       contract: null,
       contractAddr: ''
     };
  }
  
  handleChange(i, e) {
    let tokens = this.state.tokens;
    tokens[i][e.target.name]["str"] = e.target.value;
    tokens[i][e.target.name]["err"] = "";
    this.setState({ tokens });
  }

  addFormFields() {
    this.setState(({
      tokens: [...this.state.tokens, { address: [{str: "", err: ""}], id : [{str: "", err: ""}] }]
    }))
  }

  removeFormFields(i) {
    let tokens = this.state.tokens;
    tokens.splice(i, 1);
    this.setState({ tokens });
  }

  // error handlers
  tokensExists(web3, ERC721_abi, tokens) {
    var promises = [];
      for (var i = 0; i < tokens.length; i++) {
        const contract = new web3.eth.Contract(ERC721_abi, tokens[i].address.str)
        const split_tokens = tokens[i].id.str.split(', ')
        const error_idx = i;
        for (var j = 0; j < split_tokens.length; j++) {
          const idx = j;
          var promise = new Promise((resolve, reject) => {
            contract.methods.ownerOf(split_tokens[j]).call({ from: this.state.account })
              .then((result) => {
                if (result === this.state.account) {
                  resolve("current token exists")
                } else {
                  tokens[error_idx].address.err = "current address does not own this token"
                  tokens[error_idx].address.str = ''
                  throw new Error(tokens[error_idx].address.err)
                }
              })
              .catch((err) => {
                reject(err)
                tokens[error_idx].id.str = ''
                tokens[error_idx].id.err = "token " + split_tokens[idx] + " does not exist"
                this.setState({ tokens })
              })
            })
          promises.push(promise)
        }
      }
    return Promise.all(promises)
  }

  approveAll = (tokens, approveAddr) => {
    const web3 = window.web3
    const ERC721_abi = ERC721.abi

    this.tokensExists(web3, ERC721_abi, tokens).then((result) => {
        for (var i = 0; i < tokens.length; i++) {
          const contract = new web3.eth.Contract(ERC721_abi, tokens[i].address.str)
          contract.methods.setApprovalForAll(approveAddr, true).send({ from: this.state.account }).then()
            .catch((err) => {
              window.alert(err.message)
            })
        }
    }).catch((err) => {
      if (err.code !== -32603 && err.toString() !== "Error: current address does not own this token")
        window.alert(err.message)
    })
  }
    
  safeBatchTransfer = (tokens, fromAcc, toAcc) => {
    let addr_tokenid_list = []
    for (var i = 0; i < tokens.length; i++) {
        const split_tokens = tokens[i].id.str.split(', ')
        for (var j = 0; j < split_tokens.length; j++) {
            addr_tokenid_list.push(tokens[i].address.str + ', ' + split_tokens[j])
        }
    }
    this.state.contract.methods.batchTransfer(addr_tokenid_list, fromAcc, toAcc).send({ from: this.state.account })
    .then((result) => {})
    .catch((err) => {
      window.alert(err.message)
    })
}
  
  render() {
    return (
      <div className="container">
        <h1 className="title">
              <label> NFT Batch Transfer </label>
        </h1>
        <h2 className="description">
          <p> NFT BatchTransfer tool allows one to send multiple NFT tokens from different contracts at once.</p>
          <p> To make a transaction, input your NFT address in the first input box, and the token ID in the second
              input box. Note: Multiple token IDs can be inputted separated by the comma sign.
          </p>
        </h2>
        {/* Top Half */}
        <form onSubmit={(event) => {
          event.preventDefault()
          this.approveAll(this.state.tokens, this.state.contractAddr)
        }}>
          <div>
            <label className="text header"> NFT Address & Token ID: </label>
            <button className="button-add" type="button" onClick={() => this.addFormFields()}>Add Token</button>
          </div>
        {this.state.tokens.map((element, index) => (
          // Top line
          <div className="form-inline" key={index}>
            {/* Contract Address input box */}
            <div className="text address">
              <label>Contract Address: </label>
            </div>
            <div className="inputBox address">
              <input type="text" name="address" placeholder='e.g. 0x43212eB37BE18fF28A58D47186aF9Db9650bF341' value={element.address.str || ""} onChange={e => this.handleChange(index, e)} />
              {this.state.tokens[index].address.err ? <div style={{ fontSize: 12, color: 'red' }}>
                {this.state.tokens[index].address.err}
              </div> : null}
            </div>
            
            {/* Token ID input box */}
            <div className="text tokenID">
              <label>Token ID: </label>
            </div>
            <div className="inputBox tokenID">
              <input type="text" name="id" placeholder='e.g. 1, 2' value={element.id.str || ""} onChange={e => this.handleChange(index, e)} />
              {
                index ? 
                <button type="button"  className="button-remove" onClick={() => this.removeFormFields(index)}>Remove</button> 
                : null
              }
              {this.state.tokens[index].id.err ? <div style={{ fontSize: 12, color: 'red' }}>
                {this.state.tokens[index].id.err}
              </div> : null}
            </div>
          </div>
        ))}
          {/* Line under - for buttons */}
          <div className="button-section">
            <button className="button-approve" type="approve">Approve</button>
          </div>
        </form>

        {/* Bottom Half */}
        <form onSubmit={(event) => {
          event.preventDefault()
          const toAcc = this.toAcc.value.toString()
          this.safeBatchTransfer(this.state.tokens, this.state.account, toAcc)
          }}>
          
          {/* Recipient Address input box */}
          <label className="text recipient"> Recipient's Address: </label>
          <input type='text' className='form-control mb-1' placeholder='e.g. 0x6DFFA3aeE6D527B533aFA3456C5608C3E6E441ac' ref={(input) => { this.toAcc = input }} />
          {/* Transfer button */}
          <input type='submit' className='btn btn-block btn-primary' value='transfer' />
        </form>
      </div>
    );
  }
}
export default App;
